var evt = 'ontouchend' in document ? 'touchend' : 'click';
//Sorting select option JS
$('#SortBy').selectize({
  create: true,
  sortField: {
    field: 'none',
    direction: 'asc'
  },	
  dropdownParent: '.filters-toolbar__input-wrapper'
});
// Best Seller slider JS
$('.best-seller__products').slick({
  infinite: true,
  slidesToShow: 3,
   responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '120px',
        centerMode: true,
      }
    },
     {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '80px',
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

// Quotes Seller slider JS
$('.quotes-slider').slick({
  centerMode: true,
  centerPadding: '140px',
  slidesToShow: 3,
   responsive: [
     {
      breakpoint: 1060,
      settings: {
        centerMode: true,
        centerPadding: '90px',
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
      {
      breakpoint: 860,
      settings: {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        centerPadding: '180px',
        slidesToShow: 1,
      }
    },
     {
      breakpoint: 680,
      settings: {
        centerPadding: '160px',
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: false,
        centerPadding: '0px',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      }
    }
  ]
});

// Quotes Seller slider JS
// if ($(window).width() <= 880) {
  $('.product-template_slider').slick({
    centerMode: false,
    slidesToShow: 1,
    dots: true,
    //rows:0,
    dots: false,
     responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true
      }
    },
  ]
  });
// }

// Slider for Featured logos
if ($(window).width() <= 992) {
  $('.logo-bar').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    arrows:false,
     responsive: [
       {
      breakpoint: 767,
      settings: {
       slidesToShow: 3,
      }
    },
    {
      breakpoint: 580,
      settings: {
       slidesToShow: 2,
      }
    },
  ]
  });
}

// Footer Menu responsive JS
if ($(window).width() <= 880) {
  $(".site-footer__item-inner > .h4").on("click", function(e){
    e.preventDefault();
    $(this).toggleClass("mobile--toggle__title");
    $(this).siblings('.site-footer__linklist').toggleClass("mobile--toggle__menu");
    $(this).siblings('.site-footer__linklist').toggle("mobile--toggle__menu");
  });
}

// Select Color Option JS for collection's & related product swatch
$(document).on('click touchstart', '.product__options__swatch-wrap', function(){
  $(this).addClass('active');
  $(this).siblings().removeClass('active');
  var product_handle = $(this).attr('data-handle');
  var data_image = $(this).attr('data-image');
  var get_parent = $(this).parents('.global__product-item');
  get_parent.find('.global-product__main-image').attr('src',data_image);
  var currentElement_image = get_parent.find('.product--image__col.mobile .grid-view-item__image');     
  get_parent.find('a').attr('href',product_handle);
  $(currentElement_image).scroll().animate({scrollLeft: '0'}, 50);     
});
       
//product page pair plant JS for swatch and add to cart button       
$(document).on(evt, '.product__options__swatch-wrap_non', function(e){
    e.preventDefault();
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    var product_handle = $(this).attr('data-handle');
    var data_image = $(this).attr('data-image');
    var pre_get_parent = $(this).attr('data_custom');
    var get_parent =  $('.product-plant-item.plant-item_'+pre_get_parent);
    get_parent.find('.global-product__main-image').attr('src',data_image);     
    get_parent.find('a').attr('href',product_handle);

    var optionIndex = $(this).parent('.pairswatch').attr('data-option-index');
    var optionValue = $(this).find('input').val();
    var op_val= $(this).attr('data-id'); console.log(optionValue);
    var get_op = ((get_parent.find('form select.single-option-selector')).eq(optionIndex))
    get_op.val(optionValue).trigger('change');

    var variant_id_test = $(this).data('id');
    get_parent.find('form #ProductSelect-product-perfect-pair').val(variant_id_test);

    var add_cart = get_parent.find('.AddToCart');
    var get_pro_handle = get_parent.find('.product--detail__col .grid-view-item__link').attr('data-actual-href');  
    $.getJSON(get_pro_handle+'.js', function(product) {
        var pro_data = product.variants;
        var arry_index = Object.keys(pro_data).find(key => pro_data[key]['id'] == op_val);    
        if(product.variants[arry_index].available){
          add_cart.removeAttr('aria-disabled').attr('aria-label', "Add to cart");
          add_cart.find('span').text("Add to cart");
          add_cart.removeAttr('disabled').removeClass('disabled');
          get_parent.find('.product--image__col').removeClass('soldout');
        }
        else{
          add_cart.attr('aria-disabled', true).attr('aria-label', "sold out");
          add_cart.find('span').text("sold out");
          add_cart.addClass('disabled').attr('disabled', 'disabled');
          get_parent.find('.product--image__col').addClass('soldout');
        } 
    });   
  	return false;
    e.preventDefault(); 
});          

// If URL has variant id then select the swatch
$(document).ready(function(){
 var window_url = window.location.href;
 if(window_url.indexOf("?variant=") != -1){
    if(window_url.indexOf("?utm_source")!= -1){
     var data_id = window_url.split("?variant=")[1].split("?utm_source")[0];
    }
    else if(window_url.indexOf("&")!= -1){ 
     var data_id = window_url.split("?variant=")[1].split("&")[0];
    }
    else{
     var data_id = window_url.split("?variant=")[1];
    }
    $('.swatch-element[data-id*='+data_id+'] input').trigger('click');
    $('.swatch-element[data-id*='+data_id+']').addClass('active');
    $('.swatch-element[data-id*='+data_id+']').siblings().removeClass('active');
    var color_on_load_by_vid = $('.swatch-element[data-id*='+data_id+']').attr('data-value');
    $('.selected_color').text(color_on_load_by_vid);
    //on all device change slide of main image slider if url has variant id
    $('.slick-track').addClass('break-slide');
    var mob_var_id = $('.swatch-element[data-id*='+data_id+']').attr('data-img-id');
    var get_slide = $('.product-single__photo-wrapper[data-img-id*='+mob_var_id+']').parents('.slick-slide:not(.slick-cloned)').attr('data-slick-index');
    $('.product-template_slider').slick('slickGoTo', get_slide);
    setTimeout(function(){
      $('.slick-track').removeClass('break-slide');
    },500);
 }else{
  if(!$('.product-template__container').hasClass('without_variants')){
     var first_selected = $('.swatch .swatch-element.active').attr('data-id');
     var optionIndex = $('.swatch-element[data-id*='+first_selected+'] input').closest('.swatch').attr('data-option-index');
     var optionValue = $('.swatch-element[data-id*='+first_selected+'] input').val();
     $('.swatch-element[data-id*='+first_selected+'] input').closest('form').find('.single-option-selector').eq(optionIndex).val(optionValue).trigger('change');
     var color_on_load = $('.swatch .swatch-element.active').attr('data-value'); 
     var active_image = $('.swatch .swatch-element.active').attr('data-img-id');
     $('.selected_color').text(color_on_load); 
     $('.slick-track').addClass('break-slide');
      var get_slide = $('.product-single__photo-wrapper[data-img-id*='+active_image+']').parents('.slick-slide:not(.slick-cloned)').attr('data-slick-index');
      $('.product-template_slider').slick('slickGoTo', get_slide);
      setTimeout(function(){
        $('.slick-track').removeClass('break-slide');
      },500);
   }
 }
  
 //Select Color Option JS for product's swatch
 $('.swatch .swatch-element').click(function(){
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    var color_selected = $(this).attr('data-value');
    $('.selected_color').text(color_selected);
    //on all device change slide of main image slider without slide effect
    $('.slick-track').addClass('break-slide');
    var mob_var_id = $(this).attr('data-img-id');
    var get_slide = $('.product-single__photo-wrapper[data-img-id*='+mob_var_id+']').parents('.slick-slide:not(.slick-cloned)').attr('data-slick-index');
    $('.product-template_slider').slick('slickGoTo', get_slide);
    setTimeout(function(){
      $('.slick-track').removeClass('break-slide');
    },500);
 });
 //on all device change slide of main image on click on thumb  
 $('.product-single__thumbnail').click(function(){
    var thumb_id = $(this).attr('data-thumbnail-id');
    var get_slide = $('.product-single__photo-wrapper[data-img-id*='+thumb_id+']').parents('.slick-slide:not(.slick-cloned)').attr('data-slick-index');
    $('.product-template_slider').slick('slickGoTo', get_slide);
 });
});

// Mobile menu JS
$(function() {
  $(".site-header__menu").click(function() {
    $(".header--menu__popup").addClass("active");
  });
  $(".mobile-nav-wrapper > SVG, .mobile--menu__overlay").click(function() {
    $(".header--menu__popup").removeClass("active");
  });
});

$(function() {
  $(".mobile-account-menu").click(function() {
    $(".header--account__popup").addClass("active");
  });
  $(".mobile--menu__overlay").click(function() {
    $(".header--account__popup").removeClass("active");
  });
});

$(function() {
  $(".refine-mob p").click(function() {
    $(".header--filter__popup").addClass("active");
  });
  $(".mobile--menu__overlay").click(function() {
    $(".header--filter__popup").removeClass("active");
  });
});

$(function() {
  $(".sortby-mob p").click(function() {
    $(".header--sortby__popup").addClass("active");
    $(".header--sortby__popup .selectize-input .item").trigger("click");
  });
  $(".mobile--menu__overlay").click(function() {
    $(".header--sortby__popup").removeClass("active");
  });
});

// Shop Filter Toggle JS
$(".collection-sidebar__filter-items > .filters-head span").on("click", function (e) {
  e.preventDefault();
  $(this).toggleClass("mobile--toggle__title");
  $(this).siblings('.filters-head').toggleClass("mobile--toggle__menu");
  $(this).parent().siblings('.filters-options').toggle("mobile--toggle__menu");
});
if ($(window).width() <= 767) {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 92){  
      $('.filters-toolbar-wrapper').addClass("sticky");
    }
    else{
      $('.filters-toolbar-wrapper').removeClass("sticky");
    }
  });
}

// tooltip hover js
$('.tooltip_img').hover(function() {
  $('.sunlight--tooltip').addClass('hover');
}, function() {
  $('.sunlight--tooltip').removeClass('hover');
})


$('#ContactForm-subject').selectize({
  create: true,
  sortField: {
    field: 'none',
    direction: 'asc'
  },	
  dropdownParent: '.contact--sbubject__col'
});

//post to mailchimp and get a response
$(document).ready(function() {
    var $form = $('#mailchimp_form'),
        $success = $('.form_success'),
        $error = $('.form_error');  
    $('#mc-embedded-subscribe').click(function(e){
      var regEmail = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      var email = $('#mce-EMAIL').val();
      valid = false;
      e.preventDefault();
      if(!email){
        valid = false;
        $success.fadeOut();
        $error.html('This is required field.').fadeIn();
        $error.css('display', 'inline-block');
      }
      else if(email && !regEmail.test(email)) {
        valid = false;
        $success.fadeOut();
        $error.html('Please enter valid email address.').fadeIn();
        $error.css('display', 'inline-block');
      }
      else{
        valid = true;
        $.ajax({
          type: 'GET',
          url: $form.attr('action'),
          data: $form.serialize(),
          dataType : 'jsonp',
          cache: false,
          jsonppro: 'c', // trigger MailChimp to return a JSONP response
          contentType: 'application/json; charset=utf-8',
          error: function(error){
            $error.html(error.msg);
            $error.css('display', 'inline-block');
          },
          success: function(data){
            var resp = data.msg;
            if (resp.indexOf('is already subscribed to list') !== -1){
              $success.html('Looks like you\'re already on the list. We\'ll keep you posted.');
            }else if(resp.indexOf('Thank you for subscribing!') !== -1){
              $success.html('Thanks for signing up for Ansel & Ivy emails');
            }
            else{
              $success.html(data.msg);
            }
            $success.css('display', 'inline-block');
            $error.css('display', 'none');
          }
        });
        return valid;
      } 
    });
    $('#mce-EMAIL').keypress(function(e) {
            var key = e.which;
            if (key == 13) // the enter key code
            {
                $( "#mc-embedded-subscribe" ).trigger( "click" );
                return false;
            }
    });
});

/*--Newsletter Popup JS--*/
$(document).ready(function() {
    var $form = $('#newsletter_mailchimp_form'),
        $success = $('.news_mailchimp_form .form_success'),
        $error = $('.news_mailchimp_form .form_error');  
    $('#news-mc-embedded-subscribe').click(function(e){
      var regEmail = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      var email = $('#news-mce-EMAIL').val();
      valid = false;
      e.preventDefault();
      if(!email){
        valid = false;
        $success.fadeOut();
        $error.html('This is required field.').fadeIn();
        $error.css('display', 'inline-block');
      }
      else if(email && !regEmail.test(email)) {
        valid = false;
        $success.fadeOut();
        $error.html('Please enter valid email address.').fadeIn();
        $error.css('display', 'inline-block');
      }
      else{
        valid = true;
        $.ajax({
          type: 'GET',
          url: $form.attr('action'),
          data: $form.serialize(),
          dataType : 'jsonp',
          cache: false,
          jsonppro: 'c', // trigger MailChimp to return a JSONP response
          contentType: 'application/json; charset=utf-8',
          error: function(error){
            $error.html(error.msg);
            $error.css('display', 'inline-block');
          },
          success: function(data){
            var resp = data.msg;
            if (resp.indexOf('is already subscribed to list') !== -1){
              $success.html('Looks like you\'re already on the list. We\'ll keep you posted.');
            }else if(resp.indexOf('Thank you for subscribing!') !== -1){
              $success.css('display', 'none');
              $error.css('display', 'none');
              $('.step_2').removeClass('active').addClass('hidden');
              $('.step_3').removeClass('hidden').addClass('active');
              setCookie("username", "old_user", 30);
            }
            else{
              $success.html(data.msg);
              $success.css('display', 'inline-block');
            }
            $success.css('display', 'inline-block');
            $error.css('display', 'none');
          }
        });
        return valid;
      } 
    });
    $('#news-mce-EMAIL').keypress(function(e) {
            var key = e.which;
            if (key == 13) // the enter key code
            {
                $( "#news-mc-embedded-subscribe" ).trigger( "click" );
                return false;
            }
    });
});

// Address popup JS
$('.edit-add-form').click(function(){
  $(".address-edit-mobile").addClass("active");
  $("body").addClass("overlay-active");
  var form_id = $(this).attr('data-form-id');
  var form_data = $('#address_form_'+form_id).parent();
  $('.address-edit-mobile .address-edit_clone').html(form_data);
});
$(document).on('click','.mobile--menu__overlay,.p--close__icon', function() {
  var add_address_id = $('.address-edit_clone div').attr('id');
  var form_data = $('.address-edit_clone').html();
  if(add_address_id == 'AddressNewForm'){
    $('.address__form-new').html(form_data);
  }
  else{
    var form_id = $('.address-edit_clone div').attr('data-attr');
    $('li.address--main[data-form-attr*='+form_id+']').append(form_data);
  }
  $(".address-edit-mobile").removeClass("active");
  $("body").removeClass("overlay-active");
//   $(".form-vertical").addClass("hide");
});
$('#AddressNewButton').click(function(){
  var address_form_data = $('#address_form_new').parent();
  $('.address-edit-mobile .address-edit_clone').html(address_form_data);
  $(".address-edit-mobile").addClass("active");
  $("body").addClass("overlay-active");
});
// JS for update the quantity on click of icons
$('.product-form__item--quantity').each(function() {
  var spinner = $(this),
      input = spinner.find('input[type="number"]'),
      btnUp = spinner.find('.quantity-up'),
      btnDown = spinner.find('.quantity-down'),
      min = input.attr('min'),
      max = input.attr('max');

      btnUp.on('click',function() {
        var oldValue = parseFloat(input.val());
        if (oldValue >= max) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });

      btnDown.on('click',function() {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });
});

/*--Newsletter Popup JS--*/
function setCookie(cname,cvalue,exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie() {
  var user=getCookie("username");
  if (user != "") {
  } else {
    setTimeout(function(){
      $('[data-popup="popup-open-newsletter"]').fadeIn(350);
      $('[data-popup="popup-open-newsletter"]').addClass('active-popup');
    },7000);
  }
}
$(document).ready(function(){
  checkCookie();
  $('.btn-step-1').click(function(){
    var enable_step_2 = $(this).attr('data-attr');
    $('.step_1').addClass('hidden');
    $('.'+enable_step_2).removeClass('hidden').addClass('active');
  });
  $('[data-popup-close="popup-open-newsletter"]').on('click', function(e)  {
    setCookie("username", "old_user", 30);
    e.preventDefault();
  });
});

$(document).dblclick(function(e) {
   e.preventDefault(); 
});

/* A&I-May-1031 Updating Navigation Bar */
$(document).ready(function(){
  if($(window).width() > 992){
    var get_menu = $("#SiteNav").width();
    $(".site__dropdown--inner").width(get_menu);
  }
});

// Edit code for A&I-S3-July-1052 Virtual Plant Personal
if ($(window).width() <= 1060) {
$('.customer-wrapper-main').slick({
  centerMode: true,
  centerPadding: '0px',
  infinite: true,
  slidesToShow: 3,
   responsive: [
     {
      breakpoint: 1060,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
      {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        arrows: true,
        dots: true,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
      }
    }
  ]
});
}


$(function() {
  $('.virtual_slider .hero__btn, .virtual_slider-mobile .hero__btn').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
});

/* A&I-S2-June-1048 Product Page Update */

$(document).ready(function(){
  var care_tip_html = $('#shopify-section-product-care-tip').html();
  $('.new_care_tip').append(care_tip_html);
});

//sidebar fixed
$(document).ready(function(){
  if ($(window).width() > 1366) {
    $(function() {
      var top = $('.main_pro #sidebar').offset().top - 0;
      var footTop = $('#shopify-section-product-ugc').offset().top - 0;
      var maxY = footTop - $('.main_pro #sidebar').outerHeight();
      $(window).scroll(function(evt) {
        var y = $(this).scrollTop();
        if (y > top) {
          //Quand scroll, ajoute une classe ".fixed" et supprime le Css existant
          if (y < maxY) {
            $('.main_pro #sidebar').addClass('fixed').removeAttr('style');
          } else {
            //Quand la sidebar arrive au footer, supprime la classe "fixed" précèdement ajouté
            $('.main_pro #sidebar').removeClass('fixed').css({
              position: 'absolute',
              top: (maxY - top) + 'px'
            });
          }
        } else {
          $('.main_pro #sidebar').removeClass('fixed');
        }
      });
    });
  };
  });

// Quotes Seller slider JS
$('.mobile_slide').slick({
  slidesToShow: 1,
});
$(window).on('orientationchange', function() {		
	$('.mobile_slide').slick('reinit');
});

// Add to cart for paring section
$(function() {
  var top = $('#shopify-section-product-perfect-pair').offset().top - 100;
  var footTop = $('#shopify-section-custom-related-products').offset().top - 0;
  var maxY = footTop - $('#shopify-section-custom-related-products').outerHeight();
  $(window).scroll(function(evt) {
    var y = $(this).scrollTop();
    if (y > top) {
      //Quand scroll, ajoute une classe ".fixed" et supprime le Css existant
      if (y < maxY) {
        $('body').addClass('add_to-cart_remove').removeAttr('style');
      } else {
        //Quand la sidebar arrive au footer, supprime la classe "fixed" précèdement ajouté
        $('body').removeClass('add_to-cart_remove').css({
        });
      }
    } else {
      $('body').removeClass('add_to-cart_remove');
    }
  });
});

/*  A&I-S3-Sep-1056 Corporate Gifting Landing Page */
$('.customer_name').keyup(function() {
  var custom_name = $(this).val();
  $('.custom_subject').val("Corporate Gifting Inquiry - "+custom_name);
});

// Corp  slider JS
$(document).ready(function(){
  if ($(window).width() < 767) {
    $('.customized-gifts-items').slick({
      slidesToShow: 1,
    });
  };
});
$(window).on('orientationchange', function() {	
  if ($(window).width() < 767) {
    $('.customized-gifts-items').slick({
      slidesToShow: 1,
    });
  };
});

/*$(document).ready(function(){
  if(window.location.href.indexOf('/pages/accessibility-statement') > -1 ){ console.log('testtt');
    	document.addEventListener("userway:init_completed", function(event) {
          var instance = event.detail.userWayInstance;
          instance.iconVisibilityOn();
          UserWay.widgetOpen();

      });
    }
  
});*/


/* A&I-S3-May-1104 Care Guides v2 */

$(document).ready(function(){
  $('.article-content__block .size-variants .AddToCart').hide();
  $('#common-swatches .product-options').show();
  $('#common-swatches .product-options .swatch-row .header strong').hide();
  $('.article-content__block .quick-add').click(function(){
  	$('.article-content__block .size-variants .AddToCart').show();
    $(this).hide();
  });
  $('.article-content__block .size-variants .AddToCart').mouseover(function(){
  });
  
  var default_swatch = $('#common-swatches .product-options .swatch-element.active');
    var color_name = $(default_swatch).data('value');
    var image_id = $(default_swatch).data('img-id');
    
    $(default_swatch).parents('#common-swatches').siblings('.plant--img__col').find('img').removeClass('active');
    $(default_swatch).parents('#common-swatches').siblings('.plant--img__col').find('img[data-image-id="'+image_id+'"]').addClass('active');
    
    var select_colors = $(default_swatch).parents('#common-swatches').siblings('.size-variants').find('form .product-options .swatch-element[data-value="'+color_name+'"]');
    $.each(select_colors, function (i) {
      $($(select_colors)[i]).find('label').trigger('click');
      var optionIndex = $($(select_colors)[i]).closest('.swatch').attr('data-option-index');
      var select_options = $('body').find($(select_colors)[i]).closest('form').find('.single-option-selector#SingleOptionSelector-'+optionIndex);
      $(select_options).each(function(i,v){
        $(v).parents('.product-form__item').siblings('.swatch-row').find('div[data-value="'+color_name+'"] label').trigger('click');
        var optionValue = $(v).parents('.product-form__item').siblings('.swatch-row').find('div[data-value="'+color_name+'"]').data('id');
      	$(v).val(color_name);
        $(v).parents('.product-form__item').siblings('#ProductSelect-').val(optionValue);
        var href = $(v).parents('form').find('.klaviyo-bis-trigger-sold-out').attr('href').split('?variant=')[0]; 
        $(v).parents('form').find('.klaviyo-bis-trigger-sold-out').attr('href',href+'?variant='+optionValue);
      });
        
	});
  

  // A&I-S3-May-1104 Care Guides v2 - Article update the image and swatch colors to add a cart.
  $('body').on('click  touchstart', '#common-swatches .product-options .swatch-element', function(){
  	var color_name = $(this).data('value');
    var image_id = $(this).data('img-id');
    
    $(this).parents('#common-swatches').siblings('.plant--img__col').find('img').removeClass('active');
    $(this).parents('#common-swatches').siblings('.plant--img__col').find('img[data-image-id="'+image_id+'"]').addClass('active');
    
    var select_colors = $(this).parents('#common-swatches').siblings('.size-variants').find('form .product-options .swatch-element[data-value="'+color_name+'"]');
    $.each(select_colors, function (i) {
      $($(select_colors)[i]).find('label').trigger('click');
      var optionIndex = $($(select_colors)[i]).closest('.swatch').attr('data-option-index');
      var select_options = $('body').find($(select_colors)[i]).closest('form').find('.single-option-selector#SingleOptionSelector-'+optionIndex);
      $(select_options).each(function(i,v){
        $(v).parents('.product-form__item').siblings('.swatch-row').find('div[data-value="'+color_name+'"] label').trigger('click');
        var optionValue = $(v).parents('.product-form__item').siblings('.swatch-row').find('div[data-value="'+color_name+'"]').data('id');
      	$(v).val(color_name);
        $(v).parents('.product-form__item').siblings('#ProductSelect-').val(optionValue);
        var href = $(v).parents('form').find('.klaviyo-bis-trigger-sold-out').attr('href').split('?variant=')[0];
        $(v).parents('form').find('.klaviyo-bis-trigger-sold-out').attr('href',href+'?variant='+optionValue);
        
        $(this).parents('form').find('a.klaviyo-bis-trigger-sold-out').hide();
        $(this).parents('form').find('.AddToCart').show();
        
      });
        
	});
  });
  
  $('body').on('mouseover','.AddToCart',function(){
  	var image_id = $(this).siblings('.product-options').find('.swatch-row[data-option-index="1"] .swatch-element.active').data('img-id');
    $(this).parents('.size-variants').siblings('.plant--img__col').find('img').removeClass('active');
    $(this).parents('.size-variants').siblings('.plant--img__col').find('img[data-image-id="'+image_id+'"]').addClass('active');
  });
  
  $('.article--image__row form').submit(function(e){
    e.preventDefault();
    if($(this).find('#ProductSelect-').find(":selected").hasClass('unavailable')){
    	$(this).find('a.klaviyo-bis-trigger-sold-out').show();
        $(this).find('.AddToCart').hide();
        return false;
    }else{
      var params = {
          url: '/cart/add.js',
          data: $(this).serialize(),
          dataType: 'json'
        };

      $.post(params)
          .done(
            function(item) {
              //this._hideErrorMessage();
              //console.log('ttttt');
              $('body').find('#CartCount').removeClass('hide');
              $('body .site-header__cart-toggle').trigger('click');
            }.bind(this)
          )
          .fail(
            function(response) {
              console.log(response);
            }.bind(this)
          );
    }
  });
  
});
document.querySelectorAll("input.swatch-in").forEach(option =>    
  option.addEventListener("click", function(e){
    document.querySelector('afterpay-placement').amount = e.target.getAttribute("data-price");
  })
) 
document.querySelector('afterpay-placement').amount = document.querySelector("input.swatch-in.active").getAttribute("data-price");
/*--A&I-S3-Jan-1082 Gift Note in Checkout--*/
function toggleGiftNoteOnClick(){
  var element = document.getElementById("CartSpecialInstructions");
  element.classList.toggle('hide');
}